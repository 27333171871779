'use strict'

const callbackBackground = cls => () => {
  document.querySelector('.bg').setAttribute('class', 'bg ' + cls)
}

const cleanupBackground = () => {
  document.querySelector('.bg').setAttribute('class', 'bg')
}

export default [
  {
    keys: [
      'best friend',
      'friend',
      'iris',
      /mom+[ay]?/,
      'mother'
    ],

    callback: callbackBackground('iris'),
    cleanup: cleanupBackground,
    value: 'I love my mom, Iris. She is my best friend. We found each other in Kentucky. Most afternoons, I would wait for her car to pull up the driveway. Then we would sit on the patio and bask in sunlight together. She is so warm, inviting, and kind. I truly have the best momma.'
  },
  {
    keys: [
      'kristen',
      /(2nd|second|other|kentucky,ky)\s+mom/
    ],

    value: 'Kristen is my other mom. She is still in Kentucky (where I am from). i miss Kristen truly i do',

    callback: callbackBackground('kristen'),
    cleanup: cleanupBackground
  },
  {
    keys: [
      'frank',
      /pap+i+!*/,
      'uncle'
    ],

    value: 'yes i know frank. He is my uncle (technically). He is very nice like Iris. In fact, i think they are related.',

    callback: callbackBackground('frank'),
    cleanup: cleanupBackground
  },
  {
    key: /scr(a|e)tch(es|ing)?/,
    value: 'Please may I has some scretches? On chin or behind the ears, perhaps?'
  },
  {
    keys: [
      'orange',
      'oranges',
      'orangina',
      'pumpkin'
    ],

    value: 'I do like orange. Yes it is my favorite color, I would say.'
  },
  {
    keys: [
      /pet(s|ting)?/,
      /rub(s|bing)?/
    ],

    value: 'purrr'
  },
  {
    key: /(a |the )?(boop|kiss)/,
    value: 'hmm ok'
  },
  {
    key: /(you are |you'?re )(extremely |very )?(cute|handsome)/,
    value: 'yes i know this'
  },
  {
    keys: [
      /dog(go|gy)?/,
      /pup(per|py)?/
    ],

    value: 'I am a kitty. Not a puppy. My family calls me puppy as a "term of endearment". That is ok'
  },
  {
    keys: [
      /(what is|what'?s) your name\??/
    ],

    value: 'i am binx'
  },
  {
    keys: [
      /insta(gram)?/,
      'social media',
      /(how do|can) i follow you\??/,
      /more pictures\??/
    ],

    value: 'insta: @binxieboo123'
  },
  {
    keys: [
      'binx',
      'binxie',
      'binxieboo123',
      'thackery',
      'thackery binx'
    ],

    value: 'this is he'
  },
  {
    key: [
      'cat',
      'kitten',
      /kitty( cat)?/,
      'smol boi'
    ],

    value: 'that is me'
  },
  {
    keys: [
      'zach',
      'zachary'
    ],

    value: 'Weird boy steals comfiest spot on bed. Gives many pets tho. Still unsure.',

    callback: callbackBackground('zach'),
    cleanup: cleanupBackground
  },
  {
    key: /how are you( doing| feeling)?( today)?/,
    value: 'I am well thank you for inquring'
  },
  {
    keys: [
      /good (day|morning|evening)/,
      'hello',
      'hi',
      'hey',
      /yo+/
    ],

    value: 'hello good day to you'
  },
  {
    keys: [
      'hat',
      'bonnet',
      'cone'
    ],

    value: 'this thing on my head i do not like very much',

    callback: callbackBackground('bonnet'),
    cleanup: cleanupBackground
  },
  {
    keys: [
      'hand helmet',
      /hands on (me|my) head/
    ],

    value: 'this I do not understand'
  },
  {
    keys: [
      'moon',
      'luna',
      'lunar',
      'big sky cookie'
    ],

    value: 'yes i like to watch the moon from patsy\'s table 👀'
  },
  {
    keys: [
      /bak(e|er|ing)/,
      /crossaints?/,
      /(bake |make )?bread/,
      /(knead )?dough/
    ],

    value: 'oh i like to make bread. crossaints in fact are my specialty'
  },
  {
    keys: [
      'kentucky',
      'ky'
    ],

    value: 'oh i am from kentucky'
  },
  {
    keys: [
      /hug(gie|s|gy|ging)?/,
      /hold( on)?/
    ],

    value: 'i will never let go',

    callback: callbackBackground('hugging'),
    cleanup: cleanupBackground
  },
  {
    keys: [
      /parta?y+/,
      /peppa( pig)?/
    ],

    value: 'the night is ours',

    callback: callbackBackground('party'),
    cleanup: cleanupBackground
  },
  {
    keys: [
      /(i )?love you( a lot| a ton| so much)?/
    ],

    value: 'likewise'
  },
  {
    keys: [
      /snug(gy|gling|gly|gle buds|gle buddies|gle)/
    ],

    value: 'we snug'
  },
  {
    keys: [
      'cold',
      'chilly',
      /br+/,
      'nippy'
    ],

    value: 'May you share the blankets please and thank you'
  }
]
