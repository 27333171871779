'use strict'

import reworder from 'reworder'
import config from './config'

const query = document.getElementById('query')
const thoughts = document.getElementById('thoughts')
const clearQuery = document.getElementById('clear-query')

const createThought = (text, classes) => {
  const p = document.createElement('p')
  p.innerText = text
  classes = [].concat(classes).filter(Boolean)

  p.setAttribute('class', ['thought', ...classes].join(' '))

  return p
}

const getQueryText = () => (query.innerText || '').trim()
const meow = 'meoww'
const noop = () => {}
const placeholder = query.innerText = 'write 2 good boi ... 🐈‍⬛ 🌙'
const range = document.createRange()
const spanClose = '</span>'

let cb
let cleanup = noop
let lastQuery = ''
let lastThought = ''
let t = null

const reword = reworder(config, {
  caseInsensitive: true,
  variableSpacing: true
})

const clear = () => {
  cleanup()
  cleanup = noop
  lastThought = thoughts.innerHTML = ''
}

const handleQuery = text => {
  const { matches: [match] } = reword(text)

  let queryHTML = text

  if (match) {
    let net = 0

    const color = Math.random() > 0.5 ? 'green' : 'purple'
    const thought = createThought(match.value, color)
    const start = match.index + net
    const end = start + match.key.length
    const spanOpen = `<span class="${color}">`
    const sel = window.getSelection()

    queryHTML = [
      queryHTML.slice(0, start),
      spanOpen,
      queryHTML.slice(start, end),
      spanClose,
      queryHTML.slice(end)
    ].join('')

    clear()

    net += spanOpen.length + spanClose.length
    thoughts.appendChild(thought)
    query.setAttribute('contenteditable', false)
    query.innerHTML = queryHTML
    range.setStart(query, 1)

    sel.removeAllRanges()
    sel.addRange(range)
    cleanup = match.cleanup || noop
    cb = match.callback || noop
    cb()
  } else if (lastThought !== meow) {
    lastThought = meow
    const thought = createThought(meow, 'orange')
    thoughts.appendChild(thought)
  }
}

query.onkeyup = () => {
  t && clearTimeout(t)

  const queryText = getQueryText()

  if (!queryText) {
    t = setTimeout(() => {
      clear()
      t = setTimeout(() => {
        query.innerText = placeholder
      }, 10e3)
    }, 1e3)

    lastQuery = queryText

    return
  }

  if (queryText === placeholder) {
    clear()
    return
  }

  t = setTimeout(() => {
    const queryText = getQueryText()

    if (queryText && ![placeholder, lastQuery].includes(queryText)) {
      lastQuery = queryText
      handleQuery(queryText)
    }

    t = null
  }, 500)
}

clearQuery.onclick = () => {
  clear()
  lastQuery = ''
  query.innerText = ''
  query.setAttribute('contenteditable', true)
  query.onkeyup()
  query.focus()
}
